<template>
  
  <div v-if="showPrompt" class="notification-popup">
      <p>
        {{
          lang == "ar" ?
            'نود أن نبقيك على اطلاع دائم بالتحديثات المهمة والتنبيهات الفورية. قم بتمكين الإشعارات حتى لا تفوتك أي مستجدات!'
            :
            'We’d love to keep you informed with important updates and real-time alerts. Enable notifications to never miss out!'
        }}
        </p>
      <button @click="requestNotificationPermission">
        {{
          lang == "ar" ?
            'السماح بالإشعارات'
            :
            'Allow Notifications'
        }}</button>
      <button @click="declinePermission">
        {{
          lang == "ar" ?
            'لا, شكرا'
            :
            'No, thanks'
        }}
        </button>
  </div>
</template>
<script>
import { messaging, getToken, onMessage } from "@/firebase";
import Cookie from 'cookie-universal';
import axios from 'axios';

export default {
  data() {
    return {
      showPrompt: false,
      lang: Cookie().get("lang")
    }
  },
  methods: {
    async declinePermission() {
      this.showPrompt = false; 
      Cookie().set('notificationAsked', true)
      this.$store.commit("SET_FCMTOKEN", null);
    },
    async requestNotificationPermission() {
        try {
          const permission = await Notification.requestPermission();
          this.showPrompt = false; 
          if (permission === "granted") {
            Cookie().set('notificationAsked', false)
            const token = await getToken(messaging, { vapidKey: "BKFdBcZLsvy8GqypQJcVx81LsBHVVn5tQIPYw1SlN4N3CqAg4-K0Q5jTlGcT63yV7Fi7g9FRrbewVyXjEjwdBdE" });
            this.$store.commit("SET_FCMTOKEN", token);
            if(Cookie().get('Userdata'))
            {
              axios.get(`/user/change-fcm-token/`,{fcm_token: token }, {
                headers: { 'Authorization': `Bearer ${Cookie().get('Token')}` }
              })
            }
          }else{
            Cookie().set('notificationAsked', true)
            this.$store.commit("SET_FCMTOKEN", null);
          }
        } catch (error) {
          console.error("Error getting FCM token:", error);
        }
    },
    setupNotificationListener() {
        onMessage(messaging, (payload) => {
          console.log("Message received:", payload);
          // Display notification manually if needed
          new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: payload.notification.icon,
          });
        });
    },
  },
  mounted() {
    if (Notification.permission === "default" && !Cookie().get('notificationAsked')) {
      this.showPrompt = true;
    }
    this.setupNotificationListener();
  },
};
</script>

<style scoped>
.notification-popup {
    position: fixed;
    top: 10px;
    left: 50%;
    background: #fff;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%);
    z-index: 99;
    background: linear-gradient(0deg, #0a3041 0%, #225476 100%);
    color: white;
    text-align: center;
}
.notification-popup p {
  
  margin-bottom: 2rem;
}
.notification-popup button {
    margin: 0 5px;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all .3s ease-in-out;
}
.notification-popup button:hover {
    color: #fff;
    background-color: #0a3041;
}
</style>
