// src/firebase.js
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
        apiKey: "AIzaSyDX06BK2NHUkjbCgzUXlrAMm2cLBOPvihI",
        authDomain: "etmaam-1cc0f.firebaseapp.com",
        projectId: "etmaam-1cc0f",
        storageBucket: "etmaam-1cc0f.firebasestorage.app",
        messagingSenderId: "243716425820",
        appId: "1:243716425820:web:6c2757f4129a582630be99",
        measurementId: "G-VYR7TMB41D"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get the messaging instance
const messaging = getMessaging(firebaseApp);


export { messaging, getToken, onMessage };